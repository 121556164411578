<template>
  <validation-observer v-slot="{ handleSubmit }">
    <div v-if="fetch.pending">Loading...</div>
    <div v-else-if="fetch.error">{{ fetch.error }}</div>
    <template v-else-if="form">
      <v-row class="align-center">
        <v-col>
          <h1 style="margin: 0">
            Настройки практик
          </h1>
        </v-col>
        <v-col class=col-auto>
          <v-btn
            color="success"
            @click="handleSubmit(() => save.run())"
            :disabled="save.pending"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Сохранить
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <validation-provider slim rules="required" name="view" v-slot="{ errors }">
            <ViewSelect
              v-model="form.viewType"
              label="Вид карточек"
              hide-details="auto"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <ClinicHero v-model="form.image" />

      <v-row>
        <v-col>
          <locale-field
            component="v-text-field"
            dense
            label="Заголовок"
            hide-details
            outlined
            v-model="form.title"
            name="title"
          />
        </v-col>
      </v-row>

      <v-row class="mt-8">
        <v-col>
          <locale-field
            component="v-textarea"
            dense
            label="Описание"
            hide-details
            outlined
            v-model="form.body"
            name="title"
          />
        </v-col>
      </v-row>
    </template>
  </validation-observer>
</template>

<script>
import { createRequest } from '@/utils'
import { ViewSelect } from '@/components/Practice'
import PageSelect from '@/components/Page/PageSelect.vue';
import ClinicHero from '@/components/Clinic/ClinicHero.vue';
import { practicesSettingsService } from '@/services/practices-settings'
import LocaleField from '@/components/Locale/LocaleField.vue'

export default {
  components: {
    LocaleField,
    PageSelect,
    ViewSelect,
    ClinicHero,
  },
  data () {
    return {
      form: null,
      fetch: createRequest(async () => {
        const data = await practicesSettingsService.getAll()
        this.form = data
      }),
      save: createRequest(() => {
        return practicesSettingsService.update(practicesSettingsService.toDto(this.form))
      }),
    }
  },
  created () {
    return this.fetch.run()
  }
}
</script>
